import React, {useEffect, useState} from "react";
import "./About.scss";



import { useData } from "../../contexts/DataContext";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";

const About = () => {
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);

  useEffect(() => {
    fetchData("/api/picketts").then((response) => {
      setPicketts(response.data);
    });
  }, []);

  return (
    <div className="about">

      <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
      
      <div className="main-content">
        <div className="about-first-wrapper">
          <h2>Why Support?</h2>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Orci ex nulla
            est vivamus bibendum auctor platea. Nostra dui scelerisque commodo
            quisque pulvinar mi ultrices. Sociosqu lacus eros class sem tortor
            eu est nam. Sollicitudin senectus volutpat magna ut phasellus auctor
            facilisis. Turpis inceptos faucibus morbi felis; lorem aliquam
            lobortis. Lacinia mattis mi ultricies, magna molestie nec mus
            commodo. Blandit pretium leo enim; viverra tortor nam cursus metus.
            Aviverra ex ornare velit libero libero.
          </p>
        </div>
        <div className="about-first-wrapper">
          <h2>Impact</h2>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Orci ex nulla
            est vivamus bibendum auctor platea. Nostra dui scelerisque commodo
            quisque pulvinar mi ultrices. Sociosqu lacus eros class sem tortor
            eu est nam. Sollicitudin senectus volutpat magna ut phasellus auctor
            facilisis. Turpis inceptos faucibus morbi felis; lorem aliquam
            lobortis. Lacinia mattis mi ultricies, magna molestie nec mus
            commodo. Blandit pretium leo enim; viverra tortor nam cursus metus.
            Aviverra ex ornare velit libero libero.
          </p>
        </div>
        <div className="about-first-wrapper">
          <h2>Meet the Team</h2>
          <p>
            Lorem ipsum odor amet, consectetuer adipiscing elit. Orci ex nulla
            est vivamus bibendum auctor platea. Nostra dui scelerisque commodo
            quisque pulvinar mi ultrices. Sociosqu lacus eros class sem tortor
            eu est nam. Sollicitudin senectus volutpat magna ut phasellus auctor
            facilisis. Turpis inceptos faucibus morbi felis; lorem aliquam
            lobortis. Lacinia mattis mi ultricies, magna molestie nec mus
            commodo. Blandit pretium leo enim; viverra tortor nam cursus metus.
            Aviverra ex ornare velit libero libero.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
