import React, { useState, useRef, useEffect } from 'react';
import './PickettList.scss';
import Pickett from './Pickett/Pickett';

function PickettList({ picketts, show=3}) {
    const [seeMore, setSeeMore] = useState(false);
    const [listHeight, setListHeight] = useState(0);
    const [shownCount, setShownCount] = useState(4);

    const listItemRef = useRef(null);

    const calculateListHeight = () => {
        if(listItemRef.current && listItemRef.current.children.length > 0){
            const itemHeight = listItemRef.current.children[0].clientHeight;
            const listHeight = itemHeight * shownCount + (12 * (shownCount - 1));
            setListHeight(listHeight);
            console.log(listHeight);
        } else {
            setTimeout(() => {
                calculateListHeight();
            }, 100);
        }
    }

    useEffect(() => {
        calculateListHeight();
    }, [picketts, shownCount]);

    useEffect(() => {
        if(seeMore){
            setShownCount(picketts.length);
        } else {
            setShownCount(4);
        }
    }, [seeMore]);    

    return (
        <div className="pickett-list">
            <div className="list-items" style={{ height: listHeight === 0 ? "auto" : `${listHeight}px` }} ref={listItemRef}>
                {picketts.map((pickett, index) => (// only show first 3
                    <Pickett key={pickett.id} pickett={pickett}/>
                    // <Pickett key={pickett.id} pickett={pickett} />
                ))}
                {
                    picketts.length > 3 && !seeMore &&
                    <div className="gradient"></div>
                }
                <div className={`gradient ${(picketts.length > 3 && !seeMore) ? "" : "invisible"}`}></div>
            </div>
            {
                seeMore ?
                    <button onClick={()=>{setSeeMore(false)}}>see less</button>
                    :
                    <button onClick={()=>{setSeeMore(true)}}>see all</button>
            }
        </div>
    );
}

export default PickettList;