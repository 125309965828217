import React, { useState, useEffect } from "react";
import "./BasicInfo.scss";

import FileUpload from "../../FileUpload/FileUpload";
import Label from "../../Label/Label";

const BasicInfo = ({ previewInfo, setPreviewInfo, setNext }) => {
  const [range, setRange] = useState();
  const [lastChange, setLastChange] = useState();
  const [readOnly, setReadOnly] = useState(false);

  const [username, setUsername] = useState("");
  const [usernameDone, setUsernameDone] = useState(false);

  const [displayName, setDisplayName] = useState("");
  const [displayNameDone, setDisplayNameDone] = useState(false);

  const [content, setContent] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    if (previewInfo) {
      setUsername(previewInfo.username || "");
      setDisplayName(previewInfo.displayName || "");
      setContent(previewInfo.description || "");
      setProfilePicture(previewInfo.profilePicture || null);
    }
  }, [previewInfo]);


  // FEATURE: need additional checks for if the name is valid
  useEffect(() => {   
    if(username !== '' && displayName !== '' && profilePicture !== null) {
        setNext(true);
    } else {
        setNext(false);
    }
}
, [username, displayName, profilePicture]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setPreviewInfo((prev) => ({ ...prev, username: e.target.value }));
  };

  const handleDisplayNameChange = (e) => {
    setDisplayName(e.target.value);
    setPreviewInfo((prev) => ({ ...prev, displayName: e.target.value }));
  };

  useEffect(() => {
    setPreviewInfo((prev) => ({ ...prev, description: lastChange }));
  }, [lastChange]);

  const handleUsernameBlur = () => {
    if (username !== "") {
      setUsernameDone(true);
    } else {
      setUsernameDone(false);
    }
  };

  const handleDisplayNameBlur = () => {
    if (displayName !== "") {
      setDisplayNameDone(true);
    } else {
      setDisplayNameDone(false);
    }
  };

  const handleFileChange = (file) => {
    // using mime type checking here, we still need to double check on the server side but this should work fine for most cases.
    if(file.type.match("image.*")){
    setProfilePicture(file);
    setPreviewInfo((prev) => ({ ...prev, profilePicture: file }));
    } else {
      // replace with real error handling later
      alert("error case");
    }
  };

  return (
    <div className="basic-info">
      <div className="info">
        <div className="username">
          <Label label="Username">
            <div className={`${usernameDone && "done"} input-wrapper`}>
              <input
                type="text"
                value={username}
                onChange={handleUsernameChange}
                onBlur={handleUsernameBlur}
              />
            </div>
          </Label>
        </div>
        <div className="display-name">
          <Label label="Display Name">
            <div className={`${displayNameDone && "done"} input-wrapper`}>
              <input
                type="text"
                value={displayName}
                onChange={handleDisplayNameChange}
                onBlur={handleDisplayNameBlur}
              />
            </div>
          </Label>
        </div>
        <Label label="Profile Picture" className="file-upload-wrapper">
          <FileUpload
            classroomName="profile-pictures"
            onFileChange={handleFileChange}
            currentFile={profilePicture}
          />
        </Label>
      </div>
    </div>
  );
};

export default BasicInfo;