import React, { useState, useEffect } from "react";
import "./LineAnalytics.scss";
import { useData } from "../../contexts/DataContext.js";
import { useFetch } from "../../hooks/useFetch.js";

import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import Label from "../../components/Label/Label";
import AnalyticsChart from "../../components/AnalyticsChart/AnalyticsChart.jsx";
import TextInput from "../../components/TextInput/TextInput.jsx";

const LineAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const analyticsFetch = useFetch("/analytics");
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);
  const [statusArray, setStatusArray] = useState([true, false, true]);
  const [milestones, setMilestones] = useState([1000, 5000, 10000]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [keySources, setKeySources] = useState([
    "links",
    "reddit",
    "ads",
    "social media",
    "email campaigns",
  ]);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  useEffect(() => {
    const { data, loading, error } = analyticsFetch;
    if (loading) return;
    if (error) {
      console.log(error);
      return;
    }
    if (data) {
      setAnalyticsData(data);
      if (data.statusArray) setStatusArray(data.statusArray);
      if (data.milestones) setMilestones(data.milestones);
    }
  }, [analyticsFetch]);

  useEffect(() => {
    fetchData("/api/picketts").then((data) => {
      setPicketts(data.data);
    });
  }, []);

  const handleMilestoneChange = (index, value) => {
    const newMilestones = [...milestones];
    newMilestones[index] = value;
    setMilestones(newMilestones);
  };

  const saveMilestones = () => {
    console.log("Saving milestones:", milestones);
  };

  // placeholder for submitting a suggestion into very basic structure,should be replaced with more robust solution when we have enough submissions to warrent it
  // NOTE: still needs to be tested.
  const handleSuggestionSubmit = async (suggestion) => {
    try {
        const response = await fetch("/api/suggestions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ suggestion }),
        });
        if (!response.ok) throw new Error("Failed to submit suggestion");
        setConfirmationMessage({
            text: "Thank you! Your suggestion has been sent.",
            success: true,
        });
    } catch (error) {
        console.error("Error submitting suggestion:", error);
        setConfirmationMessage({
            text: "Failed to send suggestion. Please try again.",
            success: false,
        });
    } finally {
        setTimeout(() => {
            setConfirmationMessage({ text: "", success: false });
        }, 3000); // Message disappears after 3 seconds
    }
};

  if (analyticsFetch.loading) {
    return <div className="line-analytics"></div>;
  }

  return (
    <div className="line-analytics page">
      <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
      <div className="temp-spacer"></div>
      <div className="analytics-content-wrapper">
        <div className="analytics-spacer">
          <div className="primary-col">
            <Label className="graph-label" label="Growth">
              <AnalyticsChart
                endpoint={"users"}
                heading={"New Users"}
                color={"#8052FB"}
              />
            </Label>

            {/* I still think this wording could be a little more clear, moved to main col so they have more space for it but i still don't know how to test teh graph so can't see if it 
    spaces well with it rn, if it doesn't i'll find a dif location. */}
            <TextInput
              emptyInputText={"Have a suggestion for a new KPI?"}
              submitButtonText={"request"}
              onSubmit={handleSuggestionSubmit}
            />
            
            {confirmationMessage.text && (
              <div
                className={`confirmation-message ${
                  confirmationMessage.success ? "success" : "error"
                }`}
              >
                {confirmationMessage.text}
              </div>
            )}
          </div>
          <div className="secondary-col">
            <Label className="stats-label" label="Stats">
              <div className="secondary-row full-width">
                <div className="primary-value">
                  {analyticsData?.participants || "8,000"}
                </div>
                <div className="secondary-title">
                  Participants
                  <span className={`arrow ${statusArray[0] ? "up" : "down"}`}>
                    {statusArray[0] ? "▲" : "▼"}
                  </span>
                </div>
              </div>
              <div className="temp-spacer"></div>
              <div className="secondary-row half-width">
                <div className="stat-box">
                  <div
                    className="stat-value"
                    style={{
                      color: statusArray[1]
                        ? "var(--accent-green)"
                        : "var(--accent-color)",
                    }}
                  >
                    {analyticsData?.growthRate || "1.2"}%
                  </div>
                  <div
                    className="stat-label"
                    style={{
                      color: statusArray[1]
                        ? "var(--accent-green)"
                        : "var(--accent-color)",
                    }}
                  >
                    Growth Rate
                  </div>
                </div>
                <div className="stat-box">
                  <div
                    className="stat-value"
                    style={{
                      color: statusArray[2]
                        ? "var(--accent-green)"
                        : "var(--accent-color)",
                    }}
                  >
                    {analyticsData?.newUsers || "14"}
                  </div>
                  <div
                    className="stat-label"
                    style={{
                      color: statusArray[2]
                        ? "var(--accent-green)"
                        : "var(--accent-color)",
                    }}
                  >
                    New Members
                  </div>
                </div>
              </div>
            </Label>
            <Label className="milestones-label" label="Upcoming Milestones">
              <div className="secondary-row milestones-row">
                {milestones.map((milestone, index) => (
                  <div
                    key={index}
                    className="milestone-box"
                    onClick={() => setEditingIndex(index)}
                  >
                    {editingIndex === index ? (
                      <input
                        type="number"
                        value={milestone}
                        onChange={(e) =>
                          handleMilestoneChange(index, e.target.value)
                        }
                        onBlur={() => setEditingIndex(null)}
                        autoFocus
                        style={{
                          appearance: "textfield",
                          outline: "none",
                          border: "none",
                        }}
                      />
                    ) : (
                      <div className="milestone-value">{milestone}</div>
                    )}
                  </div>
                ))}
              </div>
            </Label>
            <Label className="key-sources-label" label="Key Sources">
              <div className="key-sources-ranking">
                {keySources.map((source, index) => (
                  <div key={index} className="source-rank">
                    <span className="rank-number">{index + 1}.</span>
                    <span className="source-name">{source}</span>
                  </div>
                ))}
              </div>
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineAnalytics;
