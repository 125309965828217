import React, { useState, useEffect } from "react";
import "./ExtensionAuth.scss";
import { useNavigate } from "react-router-dom";

/* global chrome */

const ExtensionAuth = () => {
    const extensionId = "kakhjmckmkcponjjllcoledhmglccone";
    const navigate = useNavigate();

    const [sent, setSent] = useState(false);

    const checkExtensionInstalled = (extensionId) => {
        return new Promise((resolve) => {
          try {
            chrome.runtime.sendMessage(
              extensionId,
              { message: "ping" },
              (response) => {
                if (chrome.runtime.lastError) {
                  console.error("Error:", chrome.runtime.lastError.message);
                  resolve(false); // Extension not installed
                } else if (response && response.success) {
                  resolve(true); // Extension installed
                } else {
                  resolve(false); // Unexpected response
                }
              }
            );
          } catch (error) {
            console.error("Exception:", error);
            resolve(false);
          }
        });
      };
    
    const sendTokenToExtension = (extensionId, token) => {
        try {
          chrome.runtime.sendMessage(
            extensionId,
            { message: "token", token },
            (response) => {
              if (chrome.runtime.lastError) {
                console.error("Error:", chrome.runtime.lastError.message);
              } else if (response && response.success) {
                console.log("Token sent to extension");
              } else {
                console.error("Unexpected response:", response);
              }
            }
          );
        } catch (error) {
          console.error("Exception:", error);
        }
      }
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            if(checkExtensionInstalled(extensionId)) {
                sendTokenToExtension(extensionId, token);
                setSent(true);
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            } else {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }

    });

    return (
        <div className="extension-auth">
            {
                sent && <p>Logged in, redirecting to home</p>
            }
        </div>
    );
    }

export default ExtensionAuth;